import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails, Avatar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '../../components/custom/button';
import IconButton from '../../components/custom/icon-button';
import TextField from '../../components/custom/text-field';
import Icon from '../../components/icon';
import { useDispatch } from 'react-redux';
import { navbarActions } from '../../redux/reducers/navbar';
import { AccountButton } from '../../components/account-btn';
import categoryServices from '../../services/category.services';
import { notifyActions } from '../../redux/reducers/notify';
import useSearchParams from '../../hooks/search-params';

interface Props {
    links: any;
    isCategoriesLoading: boolean;
}

export function Desktop({ links = [], isCategoriesLoading = false }: Props) {
    const navigator = useNavigate();
    const onSearchParams = useSearchParams();

    return (
        <div className="navbar">
            <div className="top"></div>
            <div className="body">
                <Link to="/" className="logo-wrap">
                    <img src="/images/logo.jpg" alt="Logo" className="logo" />
                </Link>

                <div className="nav-item">
                    <p className="link">
                        Categories <Icon className="icon">expand_more</Icon>
                    </p>
                    <div className="content-wrap">
                        <div className="content">
                            {isCategoriesLoading
                                ? 'Loading....'
                                : links.map((section: any, i: number) => {
                                      return (
                                          <div key={`nav-category-${i}`} className="category">
                                              <Link to={section?.link}>
                                                  <p className="title">{section.title}</p>
                                              </Link>

                                              <div className="nav-links">
                                                  {section.data.map((item: any, j: number) => {
                                                      return (
                                                          <Link
                                                              key={`nav-link-${i}-${j}`}
                                                              to={item?.link}>
                                                              {item.title}
                                                          </Link>
                                                      );
                                                  })}
                                              </div>
                                          </div>
                                      );
                                  })}
                        </div>
                    </div>
                </div>
                <div className="nav-item">
                    <Link to="/products">
                        <p className="link">All Products</p>
                    </Link>
                </div>

                <div className="search-bar">
                    <TextField
                        onClick={() => navigator('/products')}
                        onChange={(event: any) => {
                            onSearchParams({
                                path: window.location.pathname,
                                name: 'title',
                                value: event.target.value
                            });
                        }}
                        placeholder="Search Products"
                        InputProps={{
                            endAdornment: <Icon>search</Icon>
                        }}
                    />
                </div>

                <div className="buttons">
                    <AccountButton />
                </div>
            </div>
        </div>
    );
}

export function Mobile({ links = [], isCategoriesLoading = false }: Props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigator = useNavigate();
    const onSearchParams = useSearchParams();

    const [menu, setMenu] = React.useState(false);
    const [dashboardMenu, setDashboardMenu] = React.useState(false);

    React.useEffect(() => {
        if (menu) {
            disable();
        } else {
            enable();
        }
    }, [menu]);

    const onMenu = () => {
        if (location.pathname.includes('dashboard')) {
            if (dashboardMenu) {
                dispatch(navbarActions.close());
            } else {
                dispatch(navbarActions.open());
            }

            setDashboardMenu(!dashboardMenu);
            setMenu(false);
            return;
        }

        setMenu(!menu);
    };

    const disable = () => {
        document.body.classList.add('disable-scroll');
    };

    const enable = () => {
        document.body.classList.remove('disable-scroll');
    };

    return (
        <div className="navbar mobile" data-active={menu}>
            <div className="top"></div>

            <div className="body">
                <div className="wrap">
                    <Link to="/" className="logo-wrap" onClick={() => setMenu(false)}>
                        <img src="/images/logo.jpg" alt="Logo" className="logo" />
                    </Link>

                    <div className="buttons">
                        <AccountButton mobile={true} />

                        <IconButton className="menu-button" onClick={onMenu}>
                            <Icon className="icon">menu</Icon>
                        </IconButton>
                    </div>
                </div>

                <TextField
                    onClick={() => navigator('/products')}
                    onChange={(event: any) => {
                        onSearchParams({
                            path: window.location.pathname,
                            name: 'title',
                            value: event.target.value
                        });
                    }}
                    placeholder="Search Products"
                    InputProps={{
                        endAdornment: <Icon>search</Icon>
                    }}
                />
            </div>

            <div className="menu" data-active={menu}>
                <div className="links">
                    <div className="nav-item no-padding">
                        <Accordion className="accordion">
                            <AccordionSummary
                                className="accordion-summary"
                                expandIcon={<ExpandMoreIcon />}>
                                Categories
                            </AccordionSummary>
                            <AccordionDetails className="accordion-details">
                                <div className="links-wrap">
                                    <div className="content">
                                        {isCategoriesLoading
                                            ? 'Loading....'
                                            : links.map((section: any, i: number) => {
                                                  return (
                                                      <div
                                                          key={`m-nav-category-${i}`}
                                                          className="category">
                                                          <div className="title-wrap">
                                                              <p className="title">
                                                                  {section.title}
                                                              </p>
                                                              <Link
                                                                  to={section.link}
                                                                  onClick={() => setMenu(false)}>
                                                                  <Button variant="text">
                                                                      See All
                                                                  </Button>
                                                              </Link>
                                                          </div>

                                                          <div className="nav-links">
                                                              {section.data.map(
                                                                  (item: any, j: number) => {
                                                                      return (
                                                                          <Link
                                                                              onClick={() =>
                                                                                  setMenu(false)
                                                                              }
                                                                              key={`m-nav-link-${i}-${j}`}
                                                                              to={item?.link}>
                                                                              {item.title}
                                                                          </Link>
                                                                      );
                                                                  }
                                                              )}
                                                          </div>
                                                      </div>
                                                  );
                                              })}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div className="nav-item">
                        <Link to="/products" onClick={() => setMenu(false)}>
                            <p className="link">All Products</p>
                        </Link>
                    </div>
                    <div className="nav-item">
                        <Link to="/about" onClick={() => setMenu(false)}>
                            <p className="link">About Us</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function PageNavbar() {
    const dispatch = useDispatch();

    const [mobile, setMobile] = React.useState(false);

    const {
        data: categories,
        isLoading: isCategoriesLoading,
        error: categoriesError,
        isError: isCategoriesError
    }: any = categoryServices.useGetAllQuery({
        page: 0,
        all: true,
        order: [['createdAt', 'ASC']],
        find: [
            { column: 'deleted', value: 'false', like: false, operator: 'and' },
            {
                column: 'is_sub_category',
                value: 'false',
                like: false,
                operator: 'and'
            },
            {
                column: 'category.parent_id',
                value: '',
                like: false,
                as: 'children',
                required: false
            }
        ]
    });

    /* error handling */
    React.useEffect(() => {
        if (isCategoriesError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: categoriesError.data?.message
                })
            );
        }
    }, [categoriesError, isCategoriesError]);

    const links = React.useMemo(() => {
        let data = [];

        if (categories?.data) {
            data = categories?.data?.map((item: any) => {
                return {
                    title: item.name,
                    link: {
                        pathname: '/products',
                        search: `?category=${item.id}`
                    },
                    data: item.children
                        ?.filter((child: any) => !child.deleted)
                        ?.map((child: any) => {
                            return {
                                title: child.name,
                                link: {
                                    pathname: '/products',
                                    search: `?sub_category=${child.id}`
                                }
                            };
                        })
                };
            });
        }

        return data;
    }, [categories]);

    React.useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const onResize = () => setMobile(window.innerWidth < 1150);

    return mobile ? (
        <Mobile links={links} isCategoriesLoading={isCategoriesLoading} />
    ) : (
        <Desktop links={links} isCategoriesLoading={isCategoriesLoading} />
    );
}
