import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { notifyActions } from '../../redux/reducers/notify';
import { AlertTitle } from '@mui/material';

//success
//error
//warning
//info

export default function NotifyContainer() {
    const offset = 25;

    const { notifyTypes, notifyProps } = useSelector((state: any) => state.notify);
    const dispatch = useDispatch();

    const onClose = (id: any) => {
        dispatch(notifyActions.close(id));
    };

    const capitalizeTxt = (txt: string) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(); //or if you want lowercase the rest txt.slice(1).toLowerCase();
    };

    return notifyTypes.map((id: string, idx: number) => {
        const props: any = notifyProps[id];

        const options: any = {
            title: 'Error',
            type: 'error',
            message: ''
        };

        if (props.type) options.type = props.type;
        if (props.message && props.format) options.message = capitalizeTxt(props.message);

        switch (options.type) {
            case 'success':
                options.title = 'Success';
                break;
            case 'info':
                options.title = 'Info';
                break;
            case 'warning':
                options.title = 'Warning';
                break;

            default:
                options.title = 'Error';
                break;
        }

        /* auto hide after 4s */
        setTimeout(() => {
            onClose(id);
        }, 4000);

        return (
            <Snackbar
                sx={{
                    '& .SnackbarItem-variantSuccess': {
                        backgroundColor: '#DEF7EC'
                    },
                    '& .SnackbarItem-variantError': {
                        backgroundColor: '#FDE8E8'
                    },
                    '& .SnackbarItem-variantWarning': {
                        backgroundColor: '#FEFCE8'
                    },
                    '& .SnackbarItem-variantInfo': {
                        backgroundColor: '#EFF6FF'
                    }
                }}
                key={`notify-${id}`}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={true}>
                <Alert
                    onClose={() => onClose(id)}
                    elevation={6}
                    severity={options.type}
                    sx={{ width: '100%' }}>
                    <AlertTitle>{options.title}</AlertTitle>
                    {options.message}
                </Alert>
            </Snackbar>
        );
    });
}
