import React from 'react';
import { useNavigate } from 'react-router-dom';

interface SearchProps {
    path?: string;
    name?: string;
    value?: string | number | boolean;
    remove?: boolean;
    append?: boolean;
}

export default function useSearchParams() {
    const navigator = useNavigate();

    const [props, setProps]: any = React.useState<SearchProps>({});

    React.useEffect(() => {
        let params = new URLSearchParams(window.location.search);

        if (props?.name && props?.path) {
            if (props?.remove) {
                const values = params.getAll(props?.name);

                if (values.length) {
                    params.delete(props?.name);

                    for (const param_value of values) {
                        if (param_value !== (props?.value as string)) {
                            params.append(props?.name, param_value as string);
                        }
                    }
                }
            } else {
                params.set(props?.name, props?.value as string);

                const values = params.getAll(props?.name);
                if (props?.append && !values.includes(props?.value as string))
                    params.append(props?.name, props?.value as string);
            }

            navigator(
                {
                    pathname: props?.path,
                    search: params.toString()
                },
                { replace: true }
            );

            setProps({});
        }
    }, [props]);

    return setProps;
}
