import React from 'react';
import { Outlet } from 'react-router-dom';

interface Props {
    redirect?: string;
    role?: string;
    allow?: string[];
    loading?: boolean;
    all?: boolean;
    children?: any;
}

const ProtectedRoute = ({
    loading = false,
    redirect = '',
    role = '',
    allow = [],
    all = false,
    children
}: Props) => {
    if (!loading && !allow.includes(role) && !all) {
        if (redirect) window.location.assign(redirect);

        return null;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
