import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { createProps, updateProps } from '../interfaces/product/price.interface';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps
} from '../interfaces/common.interface';

const productPriceServices = createApi({
    reducerPath: 'product-price-service',
    baseQuery: baseQuery,
    tagTypes: ['ProductPrice', 'ProductPrices', 'ProductPriceOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/product-prices/create',
                body: data
            }),
            invalidatesTags: [{ type: 'ProductPrices' }, { type: 'ProductPriceOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/product-prices/update',
                body: data
            }),
            invalidatesTags: [{ type: 'ProductPrices' }, { type: 'ProductPriceOptions' }]
        }),
        get: builder.query({
            query: ({ type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/product-prices/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'ProductPrice' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/product-prices/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'ProductPrices' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/product-prices/options`,
                body: props
            }),
            providesTags: [{ type: 'ProductPriceOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/product-prices/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'ProductPrices' }, { type: 'ProductPriceOptions' }]
        })
    })
});

export default productPriceServices;
