import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import {
    getReportProps,
    getDateReportProps,
    getRangeReportProps
} from './interfaces/report.interface';

const reportServices = createApi({
    reducerPath: 'report-service',
    baseQuery: baseQuery,
    tagTypes: ['Report'],
    endpoints: (builder) => ({
        getRange: builder.mutation({
            query: ({ path, start, end }: getRangeReportProps) => ({
                method: 'GET',
                url: `/reports/${path}/${start}/${end}`
            })
        }),
        getDate: builder.mutation({
            query: ({ path, date }: getDateReportProps) => ({
                method: 'GET',
                url: `/reports/${path}/${date}`
            })
        }),
        get: builder.mutation({
            query: ({ path }: getReportProps) => ({
                method: 'GET',
                url: `/reports/${path}`
            })
        })
    })
});

export default reportServices;
