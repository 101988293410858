import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sessionUtils from '../../utils/session';
import config from '../../config/config';
import axios from 'axios';

interface userProps {
    id: string;
    kuwait_id: string;
    email: string;
    role: string;
    expire: string;
    [prop: string]: string;
}

interface actionsProps {
    payload: userProps;
}

interface stateProps {
    user: userProps;
    loading?: boolean;
}

const initState: stateProps = {
    user: {
        id: '',
        kuwait_id: '',
        email: '',
        role: '',
        expire: ''
    },
    loading: true
};

export const fetchUser = createAsyncThunk('users/verify', async (_, { dispatch }) => {
    if (!localStorage.getItem(sessionUtils.LOGIN_STATUS)) return null;

    const response = await axios.get(`${config.server_urls.api}users/verify`, {
        withCredentials: true
    });

    return response.data.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: initState,
    reducers: {
        set(state, { payload }: actionsProps) {
            state.user = payload;
            state.loading = false;

            localStorage.setItem(sessionUtils.LOGIN_STATUS, 'true');
        },
        remove(state) {
            state.user = {
                id: '',
                name: '',
                kuwait_id: '',
                email: '',
                role: '',
                expire: ''
            };

            localStorage.removeItem(sessionUtils.LOGIN_STATUS);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
