import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config/config';
import { logoutActions } from '../redux/reducers/logout';
import { userActions } from '../redux/reducers/user';

const fetchQuery = fetchBaseQuery({
    baseUrl: config.server_urls.api,
    credentials: 'include'
});

export const baseQuery = async (args, api, extraOptions) => {
    const result: any = await fetchQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
        api.dispatch(logoutActions.open());

        setTimeout(() => {
            api.dispatch(userActions.remove());
        }, 200);
    }

    return result;
};
