import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../redux/reducers/modal';
import IconButton from '../components/custom/icon-button';
import Icon from '../components/icon';
import loadable from '@loadable/component';

/* import modal containers */
const SignaturePad = loadable(() => import('./containers/signature'));

const ViewUser = loadable(() => import('./containers/user/view'));

const ConfirmOrder = loadable(() => import('./containers/order/confirm'));
const PurchaseOrder = loadable(() => import('./containers/order/purchase'));
const DeliverOrder = loadable(() => import('./containers/order/deliver'));
const ViewSupply = loadable(() => import('./containers/order/supply'));
const RejectOrder = loadable(() => import('./containers/order/reject'));
const ConfirmDeliverOrder = loadable(() => import('./containers/order/confirm-deliver'));
const OrderRequested = loadable(() => import('./containers/order/requested'));
const OrderDetails = loadable(() => import('./containers/order/details'));

const ViewText = loadable(() => import('./containers/views/text'));

const ProductFeature = loadable(() => import('./containers/product/feature'));
const ProductPrice = loadable(() => import('./containers/product/pricing'));
const ViewProduct = loadable(() => import('./containers/product/view'));
const ViewProductPrices = loadable(() => import('./containers/product/view-prices'));

const ConfirmDeleteCategory = loadable(() => import('./containers/confirm/category/delete'));
const ConfirmDeleteProduct = loadable(() => import('./containers/confirm/product/delete'));
const ConfirmDeleteProductFeature = loadable(
    () => import('./containers/confirm/product/delete-feature')
);
const ConfirmDeleteProductPrice = loadable(
    () => import('./containers/confirm/product/delete-price')
);
const ConfirmVerifyUser = loadable(() => import('./containers/confirm/user/verify'));
const ConfirmBlackListUser = loadable(() => import('./containers/confirm/user/blacklist'));
const ConfirmRegisterEmployee = loadable(() => import('./containers/user/register-employee'));
const ConfirmUpdateUser = loadable(() => import('./containers/confirm/user/update'));
const ConfirmHandoverPayment = loadable(() => import('./containers/confirm/payment/handover'));

const EditEmployee = loadable(() => import('./containers/user/edit-employee'));
const EditUser = loadable(() => import('./containers/user/edit-user'));
const EditUserPassword = loadable(() => import('./containers/user/edit-password'));
const EditSupplier = loadable(() => import('./containers/supplier'));
const ViewSupplier = loadable(() => import('./containers/supplier/view'));

const ViewUserOrders = loadable(() => import('./containers/order/user-orders'));
const ViewOrder = loadable(() => import('./containers/order/view'));

const SchedulePayment = loadable(() => import('./containers/payment/schedule'));
const CollectPayment = loadable(() => import('./containers/payment/collect'));
const HandoverPayment = loadable(() => import('./containers/payment/handover'));
const ViewPaymentHistory = loadable(() => import('./containers/payment/history'));
const ViewAllOderDetails = loadable(() => import('./containers/payment/all'));
const EditCollectPayment = loadable(() => import('./containers/payment/edit-collect'));

const ReportDuration = loadable(() => import('./containers/report'));

const UpdateBannerLink = loadable(() => import('./containers/banner-link'));

interface DialogTitleProps extends MuiDialogTitleProps {
    children: React.ReactNode;
    onClose: any;
}

const MODAL_CONTAINERS: any = {
    PRODUCT_FEATURE: {
        modal: ProductFeature,
        props: { title: 'Product Feature', maxWidth: 'md' }
    },
    PRODUCT_PRICE: {
        modal: ProductPrice,
        props: { title: 'Product Price', maxWidth: 'lg' }
    },
    PRODUCT_PRICES: {
        modal: ViewProductPrices,
        props: { title: 'Product Pricing Plan Details', maxWidth: 'lg' }
    },
    VIEW_PRODUCT: {
        modal: ViewProduct,
        props: { title: 'Product Details' }
    },
    VIEW_USER: {
        modal: ViewUser,
        props: { title: 'User Details' }
    },
    PURCHASE_ORDER: {
        modal: PurchaseOrder,
        props: { title: 'Purchase Order' }
    },
    DELIVER_ORDER: {
        modal: DeliverOrder,
        props: { title: 'Product Handover' }
    },
    REJECT_ORDER: {
        modal: RejectOrder,
        props: { title: 'Reject Order' }
    },
    VIEW_SUPPLY: {
        modal: ViewSupply,
        props: { title: "Supplier's Details" }
    },
    CONFIRM_DELETE_CATEGORY: {
        modal: ConfirmDeleteCategory,
        props: { title: 'Delete Category', hideClose: true }
    },
    CONFIRM_DELETE_PRODUCT: {
        modal: ConfirmDeleteProduct,
        props: { title: 'Delete Product', hideClose: true }
    },
    CONFIRM_DELETE_PRODUCT_FEATURE: {
        modal: ConfirmDeleteProductFeature,
        props: { title: 'Delete Product Feature', hideClose: true }
    },
    CONFIRM_DELETE_PRODUCT_PRICE: {
        modal: ConfirmDeleteProductPrice,
        props: { title: 'Delete Product Price', hideClose: true }
    },
    CONFIRM_ORDER: {
        modal: ConfirmOrder,
        props: { title: 'Confirm Order', maxWidth: 'xs' }
    },
    DETAILS_ORDER: {
        modal: OrderDetails,
        props: { title: 'Order Details' }
    },
    CONFIRM_DELIVER_ORDER: {
        modal: ConfirmDeliverOrder,
        props: { title: 'Confirm Order Deliver' }
    },
    CONFIRM_VERIFY_USER: {
        modal: ConfirmVerifyUser,
        props: { title: 'User Verification' }
    },
    CONFIRM_BLACKLIST_USER: {
        modal: ConfirmBlackListUser,
        props: { title: 'User Blacklist' }
    },
    CONFIRM_REGISTER_EMPLOYEE: {
        modal: ConfirmRegisterEmployee,
        props: { title: 'Employee Registration' }
    },
    CONFIRM_UPDATE_USER: {
        modal: ConfirmUpdateUser,
        props: { title: 'User Promotion' }
    },
    CONFIRM_HANDOVER_PAYMENT: {
        modal: ConfirmHandoverPayment,
        props: { title: 'Confirm Handover' }
    },
    VIEW_TEXT: {
        modal: ViewText,
        props: { title: 'View Content', maxWidth: 'md' }
    },
    EDIT_EMPLOYEE: {
        modal: EditEmployee,
        props: { title: 'Employee Details' }
    },
    EDIT_USER: {
        modal: EditUser,
        props: { title: 'User Details' }
    },
    EDIT_SUPPLIER: {
        modal: EditSupplier,
        props: { title: 'Supplier Details' }
    },
    VIEW_SUPPLIER: {
        modal: ViewSupplier,
        props: { title: 'Supplier Details' }
    },
    VIEW_USER_ORDERS: {
        modal: ViewUserOrders,
        props: { title: 'Order History' }
    },
    VIEW_ORDER: {
        modal: ViewOrder,
        props: { title: 'Order Details' }
    },
    SIGNATURE_PAD: {
        modal: SignaturePad,
        props: { title: 'Signature Pad' }
    },
    SCHEDULE_PAYMENT: {
        modal: SchedulePayment,
        props: { title: 'Schedule Payment', maxWidth: 'xs' }
    },
    COLLECT_PAYMENT: {
        modal: CollectPayment,
        props: { title: 'Collect Payment', maxWidth: 'xs' }
    },
    EDIT_COLLECT_PAYMENT: {
        modal: EditCollectPayment,
        props: { title: 'Collect Payment', maxWidth: 'xs' }
    },
    HANDOVER_PAYMENT: {
        modal: HandoverPayment,
        props: { title: 'Handover Collected Amount', maxWidth: 'xs' }
    },
    VIEW_PAYMENT_HISTORY: {
        modal: ViewPaymentHistory,
        props: { title: 'Payment History' }
    },
    VIEW_ALL_DETAILS: {
        modal: ViewAllOderDetails,
        props: { title: 'Payment History', maxWidth: 'md' }
    },
    REPORT_DURATION: {
        modal: ReportDuration,
        props: { title: 'Generate Report' }
    },
    ORDER_REQUESTED: {
        modal: OrderRequested,
        props: { title: '', hideTitle: true }
    },
    UPDATE_BANNER_LINK: {
        modal: UpdateBannerLink,
        props: { title: 'Banner Link', hideTitle: true }
    },
    EDIT_PASSWORD: {
        modal: EditUserPassword,
        props: { title: 'Change Password' }
    }
};

export const modalTypes = {
    PRODUCT_FEATURE: 'PRODUCT_FEATURE',
    PRODUCT_PRICE: 'PRODUCT_PRICE',
    PRODUCT_PRICES: 'PRODUCT_PRICES',
    CONFIRM_DELETE_CATEGORY: 'CONFIRM_DELETE_CATEGORY',
    CONFIRM_DELETE_PRODUCT: 'CONFIRM_DELETE_PRODUCT',
    CONFIRM_DELETE_PRODUCT_FEATURE: 'CONFIRM_DELETE_PRODUCT_FEATURE',
    CONFIRM_DELETE_PRODUCT_PRICE: 'CONFIRM_DELETE_PRODUCT_PRICE',
    VIEW_PRODUCT: 'VIEW_PRODUCT',
    VIEW_USER: 'VIEW_USER',
    CONFIRM_ORDER: 'CONFIRM_ORDER',
    PURCHASE_ORDER: 'PURCHASE_ORDER',
    DELIVER_ORDER: 'DELIVER_ORDER',
    REJECT_ORDER: 'REJECT_ORDER',
    DETAILS_ORDER: 'DETAILS_ORDER',
    VIEW_SUPPLY: 'VIEW_SUPPLY',
    CONFIRM_DELIVER_ORDER: 'CONFIRM_DELIVER_ORDER',
    VIEW_TEXT: 'VIEW_TEXT',
    CONFIRM_VERIFY_USER: 'CONFIRM_VERIFY_USER',
    CONFIRM_BLACKLIST_USER: 'CONFIRM_BLACKLIST_USER',
    CONFIRM_REGISTER_EMPLOYEE: 'CONFIRM_REGISTER_EMPLOYEE',
    CONFIRM_UPDATE_USER: 'CONFIRM_UPDATE_USER',
    EDIT_EMPLOYEE: 'EDIT_EMPLOYEE',
    EDIT_USER: 'EDIT_USER',
    EDIT_SUPPLIER: 'EDIT_SUPPLIER',
    VIEW_SUPPLIER: 'VIEW_SUPPLIER',
    VIEW_USER_ORDERS: 'VIEW_USER_ORDERS',
    VIEW_ORDER: 'VIEW_ORDER',
    SIGNATURE_PAD: 'SIGNATURE_PAD',
    SCHEDULE_PAYMENT: 'SCHEDULE_PAYMENT',
    COLLECT_PAYMENT: 'COLLECT_PAYMENT',
    HANDOVER_PAYMENT: 'HANDOVER_PAYMENT',
    CONFIRM_HANDOVER_PAYMENT: 'CONFIRM_HANDOVER_PAYMENT',
    VIEW_PAYMENT_HISTORY: 'VIEW_PAYMENT_HISTORY',
    VIEW_ALL_DETAILS: 'VIEW_ALL_DETAILS',
    REPORT_DURATION: 'REPORT_DURATION',
    ORDER_REQUESTED: 'ORDER_REQUESTED',
    UPDATE_BANNER_LINK: 'UPDATE_BANNER_LINK',
    EDIT_COLLECT_PAYMENT: 'EDIT_COLLECT_PAYMENT',
    EDIT_PASSWORD: 'EDIT_PASSWORD'
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(3)
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }: DialogTitleProps) => {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    className="popup-close-icon"
                    aria-label="close"
                    onClick={onClose}
                    color="secondary"
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 12
                    }}>
                    <Icon className="icon w-500">close</Icon>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function ModalContainer() {
    const { modalTypes, modalProps } = useSelector((state: any) => state.modal);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(modalActions.hide());
    };

    if (modalTypes.length === 0) return null;

    return modalTypes.map((type: string, idx: number) => {
        const Container: any = MODAL_CONTAINERS[type];
        const props: any = modalProps[type];
        const containerProps = Container?.props;

        let options: any = {
            hideTitle: false,
            maxWidth: 'sm'
        };

        if (containerProps) {
            options = {
                ...options,
                ...containerProps
            };
        }

        if (props) {
            options = {
                ...options,
                ...props
            };
        }

        return (
            <div key={`popup-${idx}`} className="popup-modal-wrap">
                <BootstrapDialog
                    className="popup-modal"
                    fullWidth={true}
                    maxWidth={options?.maxWidth}
                    open={true}>
                    {!options?.hideTitle && (
                        <BootstrapDialogTitle
                            className="popup-modal-title"
                            id="customized-dialog-title"
                            onClose={!options?.hideClose && onClose}>
                            {options?.title}
                        </BootstrapDialogTitle>
                    )}
                    <DialogContent style={{ overflowY: 'initial' }} className="popup-modal-content">
                        <Container.modal />
                    </DialogContent>
                </BootstrapDialog>
            </div>
        );
    });
}
