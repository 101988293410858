import { configureStore, combineReducers } from '@reduxjs/toolkit';
import modalReducer from './reducers/modal';
import notifyReducer from './reducers/notify';
import userReducer from './reducers/user';
import backdropReducer from './reducers/backdrop';
import navbarReducer from './reducers/navbar';
import logoutReducer from './reducers/logout';
import productReducer from './reducers/product';

import authServices from '../services/auth.services';
import userServices from '../services/user.services';
import categoryServices from '../services/category.services';
import orderServices from '../services/orders/order.services';
import purchaseServices from '../services/orders/purchase.services';
import supplierServices from '../services/supplier.services';
import s3Services from '../services/s3.services';

import productServices from '../services/product/product.services';
import productFeatureServices from '../services/product/feature.services';
import productPriceServices from '../services/product/price.services';
import productImageServices from '../services/product/image.services';
import priceFeatureServices from '../services/product/price-feature.services';

import downPaymentServices from '../services/payment/down.services';
import orderPaymentServices from '../services/payment/order.services';
import collectedPaymentServices from '../services/payment/collected.services';
import handoverPaymentServices from '../services/payment/handover.services';

import dashboardServices from '../services/dashboard.services';

import reportServices from '../services/report.services';

import bannerServices from '../services/banner.services';

import smsServices from '../services/sms.services';

import forgotPasswordServices from '../services/forgot-password.services';

export default configureStore({
    reducer: combineReducers({
        modal: modalReducer,
        notify: notifyReducer,
        user: userReducer,
        backdrop: backdropReducer,
        navbar: navbarReducer,
        logout: logoutReducer,
        product: productReducer,
        [userServices.reducerPath]: userServices.reducer,
        [categoryServices.reducerPath]: categoryServices.reducer,
        [productServices.reducerPath]: productServices.reducer,
        [productImageServices.reducerPath]: productImageServices.reducer,
        [productFeatureServices.reducerPath]: productFeatureServices.reducer,
        [productPriceServices.reducerPath]: productPriceServices.reducer,
        [priceFeatureServices.reducerPath]: priceFeatureServices.reducer,
        [orderServices.reducerPath]: orderServices.reducer,
        [purchaseServices.reducerPath]: purchaseServices.reducer,
        [supplierServices.reducerPath]: supplierServices.reducer,
        [downPaymentServices.reducerPath]: downPaymentServices.reducer,
        [orderPaymentServices.reducerPath]: orderPaymentServices.reducer,
        [collectedPaymentServices.reducerPath]: collectedPaymentServices.reducer,
        [handoverPaymentServices.reducerPath]: handoverPaymentServices.reducer,
        [dashboardServices.reducerPath]: dashboardServices.reducer,
        [reportServices.reducerPath]: reportServices.reducer,
        [bannerServices.reducerPath]: bannerServices.reducer,
        [s3Services.reducerPath]: s3Services.reducer,
        [smsServices.reducerPath]: smsServices.reducer,
        [forgotPasswordServices.reducerPath]: forgotPasswordServices.reducer,
        [authServices.reducerPath]: authServices.reducer
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authServices.middleware)
            .concat(userServices.middleware)
            .concat(categoryServices.middleware)
            .concat(productServices.middleware)
            .concat(productImageServices.middleware)
            .concat(productPriceServices.middleware)
            .concat(productFeatureServices.middleware)
            .concat(priceFeatureServices.middleware)
            .concat(orderServices.middleware)
            .concat(purchaseServices.middleware)
            .concat(supplierServices.middleware)
            .concat(downPaymentServices.middleware)
            .concat(orderPaymentServices.middleware)
            .concat(collectedPaymentServices.middleware)
            .concat(handoverPaymentServices.middleware)
            .concat(dashboardServices.middleware)
            .concat(reportServices.middleware)
            .concat(bannerServices.middleware)
            .concat(smsServices.middleware)
            .concat(forgotPasswordServices.middleware)
            .concat(s3Services.middleware)
});
