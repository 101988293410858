interface Props {
    className?: string;
    children: any;
    variant?: string;
    [prop: string]: any;
}

export default function Icon({ className, children, variant = '', ...props }: Props) {
    return (
        <span
            className={`material-icons${variant && `-${variant}`} ${className ? className : ''}`}
            {...props}>
            {children}
        </span>
    );
}
