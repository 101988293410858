import { createSlice } from '@reduxjs/toolkit';

interface actionsProps {
    payload: {
        type: string;
        props?: any;
    };
}

const initState: any = {
    modalTypes: [],
    modalProps: {}
};

const modalSlice = createSlice({
    name: 'modal',
    initialState: initState,
    reducers: {
        show(state, { payload }: actionsProps) {
            state.modalTypes.push(payload.type);

            if (payload.props) state.modalProps[payload.type] = payload.props;
        },
        hide(state) {
            state.modalTypes.pop();
        },
        update(state, { payload }: actionsProps) {
            if (payload.props) state.modalProps[payload.type] = payload.props;
        }
    }
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
