import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { createProps, updateProps } from './interfaces/supplier.interface';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';

const supplierServices = createApi({
    reducerPath: 'supplier-service',
    baseQuery: baseQuery,
    tagTypes: ['Supplier', 'Suppliers', 'SupplierOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/suppliers/create',
                body: data
            }),
            invalidatesTags: [{ type: 'Suppliers' }, { type: 'SupplierOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/suppliers/update',
                body: data
            }),
            invalidatesTags: [{ type: 'Suppliers' }, { type: 'SupplierOptions' }]
        }),
        get: builder.query({
            query: ({ type, value }: getProps) => ({
                method: 'POST',
                url: `/suppliers/get/${type}/${value}`
            }),
            providesTags: [{ type: 'Supplier' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/suppliers/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Suppliers' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/suppliers/options`,
                body: props
            }),
            providesTags: [{ type: 'SupplierOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/suppliers/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Suppliers' }, { type: 'SupplierOptions' }]
        })
    })
});

export default supplierServices;
