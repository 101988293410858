import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import {
    stateProps,
    createProps,
    updateProps,
    getOrderOptionsProps,
    getAllOrderProps
} from '../interfaces/order/order.interface';
import { deleteProps, getProps, searchProps } from '../interfaces/common.interface';

const orderServices = createApi({
    reducerPath: 'order-service',
    baseQuery: baseQuery,
    tagTypes: ['Order', 'Orders', 'OrderOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/orders/create',
                body: data
            }),
            invalidatesTags: [{ type: 'Orders' }, { type: 'OrderOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/orders/update',
                body: data
            }),
            invalidatesTags: [{ type: 'Orders' }, { type: 'OrderOptions' }, { type: 'Order' }]
        }),
        state: builder.mutation({
            query: ({ user_id, ...data }: stateProps) => ({
                method: 'PUT',
                url: `/orders/${user_id}/state`,
                body: data
            }),
            invalidatesTags: [{ type: 'Orders' }, { type: 'OrderOptions' }]
        }),
        get: builder.query({
            query: ({ type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/orders/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'Order' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllOrderProps) => ({
                method: 'POST',
                url: `/orders/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Orders' }]
        }),
        search: builder.query({
            query: (props: searchProps) => ({
                method: 'GET',
                url: `/orders/search`,
                params: props
            }),
            providesTags: [{ type: 'Orders' }, { type: 'OrderOptions' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOrderOptionsProps) => ({
                method: 'POST',
                url: `/orders/options`,
                body: props
            }),
            providesTags: [{ type: 'OrderOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/orders/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Orders' }, { type: 'OrderOptions' }]
        })
    })
});

export default orderServices;
