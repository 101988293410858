import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PageNavbar from './page';
import DashboardNavbar from './dashboard';

export default function Navbar() {
    const location = useLocation();
    const [dashboard, setDashboard] = React.useState(false);

    React.useEffect(() => {
        if (location.pathname.includes('dashboard')) {
            setDashboard(true);
        } else {
            setDashboard(false);
        }
    }, [location]);

    return dashboard ? <DashboardNavbar /> : <PageNavbar />;
}
