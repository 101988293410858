import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { signInProps, signUpProps } from './interfaces/auth.interface';

const authServices = createApi({
    reducerPath: 'auth-service',
    baseQuery: baseQuery,
    tagTypes: ['Auth'],
    endpoints: (builder) => ({
        signup: builder.mutation({
            query: (data: signUpProps) => ({
                method: 'POST',
                url: '/users/signup',
                body: data
            }),
            invalidatesTags: [{ type: 'Auth' }]
        }),
        signin: builder.mutation({
            query: ({ kuwait_id, password }: signInProps) => ({
                method: 'POST',
                url: '/users/signin',
                body: { kuwait_id, password }
            }),
            invalidatesTags: [{ type: 'Auth' }]
        }),
        verify: builder.query({
            query: ({}) => ({
                method: 'GET',
                url: '/users/verify'
            })
        })
    })
});

export default authServices;
