import './styles/index.scss';
import './styles/components/index.scss';
import './styles/modals/index.scss';
import './styles/layout/index.scss';
import './styles/pages/index.scss';

import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import ModalContainer from './modals';
import NotifyContainer from './components/notify';
import BackdropContainer from './components/back-drop';
import LogoutContainer from './components/logout';
import Footer from './layout/footer';
import Navbar from './layout/navbar';
import Home from './pages/home';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from './routes';
import authServices from './services/auth.services';
import moment from 'moment';
import { fetchUser } from './redux/reducers/user';
import WhatsappIcon from './components/whatsapp-icon';

const Login = loadable(() => import('./pages/login'));
const Register = loadable(() => import('./pages/register'));
const Products = loadable(() => import('./pages/products'));
const ForgotPassword = loadable(() => import('./pages/forgot-password'));
const ChangePassword = loadable(() => import('./pages/change-password'));
const Product = loadable(() => import('./pages/products/product'));
const Categories = loadable(() => import('./pages/categories'));
const About = loadable(() => import('./pages/about'));
const Dashboard = loadable(() => import('./pages/dashboard'));
const PrivacyPolicy = loadable(() => import('./pages/privacy-policy'));
const TermsAndConditions = loadable(() => import('./pages/terms-conditions'));
const NotFound = loadable(() => import('./pages/not-found'));

function App() {
    const dispatch = useDispatch<any>();
    const location = useLocation();
    const { loading: userLoading, user: authUser } = useSelector((state: any) => state.user);

    React.useEffect(() => {
        dispatch(fetchUser());
    }, []);

    /* scroll to top on route change */
    React.useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [location?.pathname]);

    return (
        <div className="app">
            <Navbar />
            <main className="main">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/change-password/:token" element={<ChangePassword />} />
                    <Route path="/product/:id?" element={<Product />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route
                        path="/dashboard/*"
                        element={
                            <ProtectedRoute
                                loading={userLoading}
                                redirect="/login"
                                role={authUser?.role}
                                allow={['SUPER_ADMIN', 'ADMIN', 'EMPLOYEE', 'USER']}>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>

                {/* whatsapp icon */}
                <WhatsappIcon />
            </main>
            <Footer />

            {/* logout container */}
            <LogoutContainer />
            {/* popup modals container */}
            <ModalContainer />
            {/* notify container */}
            <NotifyContainer />
            {/* backdrop container */}
            <BackdropContainer />
        </div>
    );
}

export default App;
