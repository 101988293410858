import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { createProps, paymentsProps, updateProps } from '../interfaces/payment/collected.interface';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps
} from '../interfaces/common.interface';

const collectedPaymentServices = createApi({
    reducerPath: 'collected-payment-service',
    baseQuery: baseQuery,
    tagTypes: [
        'CollectedPayment',
        'CollectedPayments',
        'CollectedPaymentOptions',
        'CollectedEmployeePayment'
    ],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/collected-payments/create',
                body: data
            }),
            invalidatesTags: [{ type: 'CollectedPayments' }, { type: 'CollectedPaymentOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/collected-payments/update',
                body: data
            }),
            invalidatesTags: [{ type: 'CollectedPayments' }, { type: 'CollectedPaymentOptions' }]
        }),
        employeePayments: builder.query({
            query: ({ employee_id }: paymentsProps) => ({
                method: 'GET',
                url: `/collected-payments/payments/${employee_id}`
            }),
            providesTags: [{ type: 'CollectedEmployeePayment' }]
        }),
        get: builder.query({
            query: ({ type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/collected-payments/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'CollectedPayment' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/collected-payments/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'CollectedPayments' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/collected-payments/options`,
                body: props
            }),
            providesTags: [{ type: 'CollectedPaymentOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/collected-payments/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'CollectedPayments' }, { type: 'CollectedPaymentOptions' }]
        })
    })
});

export default collectedPaymentServices;
