import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import { dailyProps, monthlyProps, userProps } from './interfaces/dashboard.interface';

const dashboardServices = createApi({
    reducerPath: 'dashboard-service',
    baseQuery: baseQuery,
    tagTypes: [
        'DailyIncome',
        'MonthlyIncome',
        'MonthlySold',
        'DailySold',
        'MonthlyInstallment',
        'TotalProfit',
        'ClearedOrders',
        'TimeoutCustomers',
        'BadCustomers',
        'MonthlyChartData',
        'UserCollectionsData',
        'UserHandoverData'
    ],
    endpoints: (builder) => ({
        daily: builder.query({
            query: ({ date }: dailyProps) => ({
                method: 'GET',
                url: `/dashboard/daily/${date}`
            })
            // providesTags: [{ type: 'DailyIncome' }]
        }),
        monthly: builder.query({
            query: ({ month }: monthlyProps) => ({
                method: 'GET',
                url: `/dashboard/monthly/${month}`
            })
            // providesTags: [{ type: 'MonthlyIncome' }]
        }),
        monthlySold: builder.query({
            query: ({ month }: monthlyProps) => ({
                method: 'GET',
                url: `/dashboard/monthly-sold/${month}`
            })
            // providesTags: [{ type: 'MonthlySold' }]
        }),
        dailySold: builder.query({
            query: ({ date }: dailyProps) => ({
                method: 'GET',
                url: `/dashboard/daily-sold/${date}`
            })
            // providesTags: [{ type: 'DailySold' }]
        }),
        monthlyInstallment: builder.query({
            query: ({ month }: monthlyProps) => ({
                method: 'GET',
                url: `/dashboard/monthly-installment/${month}`
            })
            // providesTags: [{ type: 'MonthlyInstallment' }]
        }),
        totalProfit: builder.query({
            query: ({}) => ({
                method: 'GET',
                url: `/dashboard/total-profit`
            })
            // providesTags: [{ type: 'TotalProfit' }]
        }),
        clearedOrders: builder.query({
            query: ({ month }: monthlyProps) => ({
                method: 'GET',
                url: `/dashboard/cleared-orders/${month}`
            })
            // providesTags: [{ type: 'ClearedOrders' }]
        }),
        timeoutCustomers: builder.query({
            query: ({ date }: dailyProps) => ({
                method: 'GET',
                url: `/dashboard/timeout-customers/${date}`
            })
            // providesTags: [{ type: 'TimeoutCustomers' }]
        }),
        badCustomers: builder.query({
            query: ({ date }: dailyProps) => ({
                method: 'GET',
                url: `/dashboard/bad-customers/${date}`
            }),
            providesTags: [{ type: 'BadCustomers' }]
        }),
        chartData: builder.query({
            query: ({ month }: monthlyProps) => ({
                method: 'GET',
                url: `/dashboard/month-chart-data/${month}`
            }),
            providesTags: [{ type: 'MonthlyChartData' }]
        }),
        userCollections: builder.query({
            query: ({ date, user_id }: userProps) => ({
                method: 'GET',
                url: `/dashboard/user-collections/${date}/${user_id}`
            }),
            providesTags: [{ type: 'UserCollectionsData' }]
        }),
        userHandovers: builder.query({
            query: ({ user_id }: userProps) => ({
                method: 'GET',
                url: `/dashboard/user-handovers/${user_id}`
            }),
            providesTags: [{ type: 'UserHandoverData' }]
        })
    })
});

export default dashboardServices;
