import React from 'react';
import MuiSkeleton, { SkeletonProps as MuiSkeletonProps } from '@mui/material/Skeleton';

interface SkeletonProps extends MuiSkeletonProps {
    loading?: boolean;
    children?: any;
}

export default function Skeleton({
    loading = true,
    children,
    className,
    animation = 'wave',
    ...props
}: SkeletonProps) {
    return loading ? (
        <MuiSkeleton
            animation={animation}
            className={`skeleton ${className ? className : ''}`}
            {...props}
        />
    ) : (
        children
    );
}
