import React from 'react';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

export default function TextField({
    size = 'small',
    type = 'text',
    className,
    label = '',
    fullWidth = true,
    ...props
}: TextFieldProps) {
    return (
        <div className="input-wrap">
            {label && <span className="label">{label}</span>}

            <MuiTextField
                size={size}
                type={type}
                fullWidth={fullWidth}
                className={`input ${className ? className : ''}`}
                {...props}
            />
        </div>
    );
}
