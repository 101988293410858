import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../components/custom/icon-button';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import config from '../config/config';

export default function Footer() {
    const Links = [
        [
            { link: '/', name: 'Home' },
            /* { link: '/', name: 'Privacy Policy' }, */
            { link: '/about', name: 'About Us' },
            { link: '/login', name: 'Login' },
            { link: '/register', name: 'Register' }
        ],
        [
            { link: '/terms-and-conditions', name: 'Terms & Conditions' },
            { link: '/privacy-policy', name: 'Privacy Policy' }
        ]
    ];

    return (
        <div id="footer">
            <div className="sections">
                <div className="section">
                    <span className="main-title">SMART CO</span>
                    <p className="desc">
                        The evolution of Smart phone Company pvt LTD is among remarkable success
                        stories in Kuwait.It was founded in 2016 by MR.Hamza Haleem to assist with
                        the people of installment plans who have financial difficulties for
                        purchasing smartphones and electronics.We warmly welcome our customers by
                        offering the most genuine 100% guaranteed electronics to customers as per
                        customer orders.
                    </p>
                </div>
                <div className="section">
                    <span className="title">Quick Links</span>
                    <div className="items">
                        {Links[0].map((item: any, i: number) => {
                            return (
                                <Link key={`links-0-${i}`} to={item.link} className="link">
                                    {item.name}
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="section">
                    <span className="title">Important Links</span>
                    <div className="items">
                        {Links[1].map((item: any, i: number) => {
                            return (
                                <Link key={`links-1-${i}`} to={item.link} className="link">
                                    {item.name}
                                </Link>
                            );
                        })}
                    </div>
                </div>
                <div className="section">
                    <span className="title">Contact Us</span>
                    <div className="items">
                        <div className="item">
                            <p className="contact-title">Hotline</p>
                            <span>:</span>
                            <p className="desc">
                                <a href={`tel:${config.details.phone.value}`}>
                                    {config.details.phone.label}
                                </a>
                            </p>
                        </div>
                        <div className="item">
                            <p className="contact-title">Email</p>
                            <span>:</span>
                            <p className="desc">
                                <a href={`mailto:${config.details.email.value}`}>
                                    {config.details.email.label}
                                </a>
                            </p>
                        </div>
                        <div className="item">
                            <p className="contact-title">Visit Us</p>
                            <span>:</span>
                            <p className="desc">
                                <a href={config.details.address.value}>
                                    {config.details.address.label}
                                </a>
                            </p>
                        </div>
                        <div className="socials">
                            {/* <InstagramIcon className="icon" />
                            <FacebookIcon className="icon" /> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <span className="text">All rights reserved smartphone.co</span>
            </div>
        </div>
    );
}
