import { createSlice } from '@reduxjs/toolkit';

const initState: any = {
    open: false
};

const logoutSlice = createSlice({
    name: 'logout',
    initialState: initState,
    reducers: {
        open(state) {
            state.open = true;
        },
        close(state) {
            state.open = false;
        }
    }
});

export const logoutActions = logoutSlice.actions;

export default logoutSlice.reducer;
