import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from './circular-progress';
import { logoutActions } from '../redux/reducers/logout';

export default function Logout() {
    const dispatch = useDispatch();
    const { open } = useSelector((state: any) => state.logout);
    const { loading, user: authUser } = useSelector((state: any) => state.user);

    React.useEffect(() => {
        if (open && !authUser.id) {
            if (!loading) window.location.assign('/');
            setTimeout(() => {
                dispatch(logoutActions.close());
            }, 1500);
        }
    }, [loading, open, authUser]);

    return (
        <MuiBackdrop
            className="logout"
            sx={{ background: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}>
            Logging out
            <CircularProgress />
        </MuiBackdrop>
    );
}
