import React from 'react';

export default function WhatsappIcon() {
    return window.location.pathname.includes('/dashboard') ? null : (
        <a
            draggable="false"
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=96569966882&text&type=phone_number&app_absent=0"
            className="whatsapp-icon">
            <img
                src="/images/whatsapp.png"
                alt="Whatsapp Icon"
                className="image"
                draggable="false"
            />
        </a>
    );
}
