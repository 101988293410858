import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Paper } from '@mui/material';
import Card from '../components/cards';
import { Link } from 'react-router-dom';
import Icon from '../components/icon';
import DetailCard from '../components/cards/details';
import NavArrow from '../components/arrows';
import Button from '../components/custom/button';
import productServices from '../services/product/product.services';
import { useDispatch } from 'react-redux';
import { notifyActions } from '../redux/reducers/notify';
import categoryServices from '../services/category.services';
import _ from 'lodash';
import bannerServices from '../services/banner.services';
import Skeleton from '../components/custom/skeleton';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 6
    },
    laptop: {
        breakpoint: { max: 1500, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 770 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 770, min: 550 },
        items: 2
    },
    minMobile: {
        breakpoint: { max: 550, min: 0 },
        items: 2
    }
};

const testimonials = [
    {
        desc: 'I got my Samsung 32  Thank you Smart phone co  super legit and original phones. I asked 5 shops for their prices but smartphone co  was the cheapest one and yet an original phone this is our 2nd time puchased mobile phone with my co-worker',
        customer: 'Sarath'
    },
    {
        desc: 'I love shopping at Smartco! Their monthly payment plan allowed me to afford the latest gadgets without breaking the bank. Excellent service and quality products.'
        //customer: 'Customer Name'
    },
    {
        desc: "Smartco exceeded my expectations with their convenient monthly payment option. I was able to upgrade my electronics hassle-free. I'm a satisfied customer!"
        //customer: 'Customer Name'
    },
    {
        desc: "I couldn't be happier with my experience at Smartco. Their monthly payment option made it possible for me to own the latest tech without straining my finances. I highly recommend them!"
        //customer: 'Customer Name'
    },
    {
        desc: 'My experiance with smart phone company was very good. They respond to my messages daily and got the tablet on time, because I needed it for a present. Will order again in the future.',
        customer: 'Niro'
    },
    {
        desc: 'Amazing experience as always since this was not my first purchase through Smart Phone Company. I would also commend fair pricing and fast delivery',
        customer: 'Ramesh'
    }
];

export default function Home() {
    const dispatch = useDispatch();
    const [showCarouselArrows, setShowCarouselArrows] = React.useState(true);

    /* query */ const {
        data: bannerImages,
        isLoading: isBannerImagesLoading,
        error: bannerImagesError,
        isError: isBannerImagesError
    }: any = bannerServices.useGetAllQuery({
        page: 0,
        all: true,
        order: [['order', 'ASC']]
    });

    const {
        data: bestSellerProducts,
        isLoading: isBestSellerProductsLoading,
        error: bestSellerProductsError,
        isError: isBestSellerProductsError
    }: any = productServices.useSearchQuery({
        offset: 0,
        limit: 6,
        best_seller: true
    });

    const {
        data: featuredProducts,
        isLoading: isFeaturedProductsLoading,
        error: featuredProductsError,
        isError: isFeaturedProductsError
    }: any = productServices.useSearchQuery({
        offset: 0,
        limit: 6,
        featured: true
    });

    const {
        data: categories,
        isLoading: isCategoriesLoading,
        error: categoriesError,
        isError: isCategoriesError
    }: any = categoryServices.useGetAllQuery({
        page: 0,
        limit: 11,
        order: [['createdAt', 'ASC']],
        find: [
            { column: 'deleted', value: 'false', like: false, operator: 'and' },
            {
                column: 'is_sub_category',
                value: 'false',
                like: false,
                operator: 'and'
            }
        ]
    });

    /* error handling */
    React.useEffect(() => {
        if (isBestSellerProductsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: bestSellerProductsError.data?.message
                })
            );
        }
        if (isFeaturedProductsError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: featuredProductsError.data?.message
                })
            );
        }
        if (isCategoriesError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: categoriesError.data?.message
                })
            );
        }
        if (isBannerImagesError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: bannerImagesError.data?.message
                })
            );
        }
    }, [
        bestSellerProductsError,
        bestSellerProductsError,
        featuredProductsError,
        isFeaturedProductsError,
        categoriesError,
        isCategoriesError,
        bannerImagesError,
        isBannerImagesError
    ]);

    React.useEffect(() => {
        const setArrowCondition = () => setShowCarouselArrows(window.innerWidth > 770);
        window.addEventListener('resize', () => {
            setArrowCondition();
        });
        setArrowCondition();

        return () => {
            window.removeEventListener('resize', () => {
                setArrowCondition();
            });
        };
    }, []);

    return (
        <div id="home" className="container">
            <div className="top-carousel">
                <Carousel
                    className="banner-carousel"
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    showDots={false}
                    arrows={showCarouselArrows}
                    infinite={true}
                    responsive={{
                        desktop: {
                            breakpoint: { max: 9999999, min: 0 },
                            items: 1
                        }
                    }}
                    customLeftArrow={<NavArrow type="left" />}
                    customRightArrow={<NavArrow type="right" />}>
                    {isBannerImagesLoading ? (
                        <Paper className="carousel-paper">
                            <div className="carousel-item">
                                <Skeleton height={700}></Skeleton>
                            </div>
                        </Paper>
                    ) : bannerImages?.data?.length === 0 ? (
                        <Paper className="carousel-paper"></Paper>
                    ) : (
                        bannerImages?.data?.map((item: any, i: number) => {
                            return (
                                <Paper key={`carousel-image-${i}`} className="carousel-paper">
                                    {item?.link ? (
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            className="carousel-item">
                                            <img
                                                src={item.image}
                                                alt="Carousel Banner"
                                                className="image"
                                            />
                                        </a>
                                    ) : (
                                        <div className="carousel-item">
                                            <img
                                                src={item.image}
                                                alt="Carousel Banner"
                                                className="image"
                                            />
                                        </div>
                                    )}
                                </Paper>
                            );
                        })
                    )}
                </Carousel>
            </div>

            <div className="sections container-mw">
                <div className="section">
                    <div className="main-title-wrap">
                        <p className="main-title">Shop By Category</p>
                    </div>
                    <div className="cards">
                        {isCategoriesLoading
                            ? new Array(11).fill(0).map((_: any, i: any) => {
                                  return (
                                      <Card
                                          key={`category-${i}`}
                                          loading
                                          image=""
                                          link=""
                                          title=""
                                      />
                                  );
                              })
                            : categories?.data?.length === 0
                            ? 'No categories yet'
                            : categories?.data?.map((item: any, i: number) => {
                                  return (
                                      <Card
                                          key={`category-${i}`}
                                          image={item?.image}
                                          link={{
                                              pathname: '/products',
                                              search: `?category=${item.id}`
                                          }}
                                          title={item.name}
                                      />
                                  );
                              })}
                        {isCategoriesLoading && <Card loading image="" link="" title="" />}
                        {!isCategoriesLoading && categories?.data?.length !== 0 && (
                            <Link to={'/categories'} className="card fixed-card">
                                <div className="content">
                                    <p className="title">See All Categories</p>

                                    <div className="arrow">
                                        <Icon className="icon w-700">arrow_upward</Icon>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>

                <div className="section">
                    <div className="main-title-wrap">
                        <p className="main-title">Best Sellers</p>

                        <Link
                            to={{
                                pathname: '/products',
                                search: '?best_seller=true'
                            }}>
                            <Button variant="contained" className="hover-btn no-shadow">
                                View All
                            </Button>
                        </Link>
                    </div>
                    <Carousel
                        className="multi-carousel"
                        autoPlay={false}
                        showDots={false}
                        infinite={false}
                        arrows={showCarouselArrows}
                        responsive={responsive}
                        customLeftArrow={<NavArrow type="left" />}
                        customRightArrow={<NavArrow type="right" />}>
                        {isBestSellerProductsLoading ? (
                            new Array(6).fill(0).map((item: any, i: number) => {
                                return (
                                    <div key={`seller-${i}`} className="detail-card-wrap">
                                        <DetailCard loading price="" image="" title="" />
                                    </div>
                                );
                            })
                        ) : bestSellerProducts?.data?.length === 0 ? (
                            <Paper className="carousel-paper"></Paper>
                        ) : (
                            bestSellerProducts?.data?.map((item: any, i: number) => {
                                let images = _.orderBy(item?.images || [], ['order'], ['asc']);
                                let price: any = Math.min(
                                    ...(item?.prices?.map((_) => _.price) || [])
                                );

                                return (
                                    <div key={`seller-${i}`} className="detail-card-wrap">
                                        <DetailCard
                                            title={item?.name}
                                            image={images?.[0]?.image || ''}
                                            link={{ to: `/product/${item?.id}` }}
                                            price={price || 0}
                                            hideOnMobile
                                        />
                                    </div>
                                );
                            })
                        )}
                    </Carousel>
                </div>

                <div className="section">
                    <div className="main-title-wrap">
                        <p className="main-title">Featured Products</p>

                        <Link
                            to={{
                                pathname: '/products',
                                search: '?featured=true'
                            }}>
                            <Button variant="contained" className="hover-btn no-shadow">
                                View All
                            </Button>
                        </Link>
                    </div>
                    <Carousel
                        className="multi-carousel"
                        autoPlay={false}
                        showDots={false}
                        infinite={false}
                        arrows={showCarouselArrows}
                        responsive={responsive}
                        customLeftArrow={<NavArrow type="left" />}
                        customRightArrow={<NavArrow type="right" />}>
                        {isFeaturedProductsLoading ? (
                            new Array(6).fill(0).map((item: any, i: number) => {
                                return (
                                    <div key={`seller-${i}`} className="detail-card-wrap">
                                        <DetailCard loading price="" image="" title="" />
                                    </div>
                                );
                            })
                        ) : featuredProducts?.data?.length === 0 ? (
                            <Paper className="carousel-paper"></Paper>
                        ) : (
                            featuredProducts?.data?.map((item: any, i: number) => {
                                let images = _.orderBy(item?.images || [], ['order'], ['asc']);
                                let price: any = Math.min(
                                    ...(item?.prices?.map((_) => _.price) || [])
                                );

                                return (
                                    <div key={`seller-${i}`} className="detail-card-wrap">
                                        <DetailCard
                                            title={item?.name}
                                            image={images?.[0]?.image || ''}
                                            link={{ to: `/product/${item?.id}` }}
                                            price={price || 0}
                                            hideOnMobile
                                        />
                                    </div>
                                );
                            })
                        )}
                    </Carousel>
                </div>
            </div>

            <div className="testimonials-section container-mw">
                <div className="section">
                    <div className="card">
                        <div className="details">
                            <p className="detail-title">Register as a member</p>
                            <p className="desc">
                                Get yourself register to place order trough smart co.
                            </p>

                            <Link to="/register">
                                <Button variant="contained" size="medium" className="no-shadow">
                                    Register Now
                                </Button>
                            </Link>
                        </div>

                        <img src="/images/home/pc.png" alt="Computer" className="image" />
                    </div>
                </div>

                <div className="section testimonials">
                    <p className="testimonials-title">Testimonials</p>

                    <div className="carousel-wrap">
                        <Carousel
                            className="testimonials-carousel"
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            showDots={false}
                            arrows={showCarouselArrows}
                            infinite={true}
                            responsive={{
                                desktop: {
                                    breakpoint: { max: 9999999, min: 0 },
                                    items: 1
                                }
                            }}
                            customLeftArrow={<NavArrow type="left" />}
                            customRightArrow={<NavArrow type="right" />}>
                            {testimonials?.length === 0 ? (
                                <Paper className="carousel-paper"></Paper>
                            ) : (
                                testimonials.map((item: any, i: number) => {
                                    return (
                                        <Paper
                                            key={`carousel-testimonials-${i}`}
                                            className="carousel-paper">
                                            <div className="content">
                                                <p className="text">{item.desc}</p>

                                                <p className="name">{item.customer}</p>
                                            </div>
                                        </Paper>
                                    );
                                })
                            )}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}
