import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { createProps, updateProps } from './interfaces/category.interface';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';

const categoryServices = createApi({
    reducerPath: 'category-service',
    baseQuery: baseQuery,
    tagTypes: ['Category', 'Categories', 'CategoryOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/categories/create',
                body: data
            }),
            invalidatesTags: [{ type: 'Categories' }, { type: 'CategoryOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/categories/update',
                body: data
            }),
            invalidatesTags: [{ type: 'Categories' }, { type: 'CategoryOptions' }]
        }),
        get: builder.query({
            query: ({ type, value }: getProps) => ({
                method: 'POST',
                url: `/categories/get/${type}/${value}`
            }),
            providesTags: [{ type: 'Category' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/categories/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Categories' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/categories/options`,
                body: props
            }),
            providesTags: [{ type: 'CategoryOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/categories/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Categories' }, { type: 'CategoryOptions' }]
        })
    })
});

export default categoryServices;
