import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import {
    changePasswordProps,
    mailProps,
    verifyProps
} from './interfaces/forgot-password.interface';

const forgotPasswordServices = createApi({
    reducerPath: 'forgot-password-service',
    baseQuery: baseQuery,
    tagTypes: ['ForgotPassword'],
    endpoints: (builder) => ({
        sendMail: builder.mutation({
            query: ({ email }: mailProps) => ({
                method: 'GET',
                url: `/forgot-password/mail/${email}`
            })
        }),
        verifyOTP: builder.mutation({
            query: (data: verifyProps) => ({
                method: 'POST',
                url: `/forgot-password/verify`,
                body: data
            })
        }),
        changePassword: builder.mutation({
            query: (data: changePasswordProps) => ({
                method: 'POST',
                url: `/forgot-password/change`,
                body: data
            })
        })
    })
});

export default forgotPasswordServices;
