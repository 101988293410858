import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { createProps, updateProps } from '../interfaces/order/purchase.interface';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps
} from '../interfaces/common.interface';

const purchaseServices = createApi({
    reducerPath: 'purchase-service',
    baseQuery: baseQuery,
    tagTypes: ['Purchase', 'Purchases', 'PurchaseOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ user_id, ...data }: createProps) => ({
                method: 'POST',
                url: `/purchases/${user_id}/create`,
                body: data
            }),
            invalidatesTags: [{ type: 'Purchases' }, { type: 'PurchaseOptions' }]
        }),
        update: builder.mutation({
            query: ({ user_id, ...data }: updateProps) => ({
                method: 'PATCH',
                url: `/purchases/${user_id}/update`,
                body: data
            }),
            invalidatesTags: [{ type: 'Purchases' }, { type: 'PurchaseOptions' }]
        }),
        get: builder.query({
            query: ({ type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/purchases/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'Purchase' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/purchases/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Purchases' }, { type: 'PurchaseOptions' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/purchases/options`,
                body: props
            }),
            providesTags: [{ type: 'PurchaseOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/purchases/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Purchases' }, { type: 'PurchaseOptions' }]
        })
    })
});

export default purchaseServices;
