import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from '../custom/skeleton';

interface LinkProps {
    search?: any;
    pathname?: string;
}

interface Props {
    loading?: boolean;
    image: string;
    title: string;
    link: string | LinkProps;
}

export default function Card({ loading = false, image, title, link }: Props) {
    const Wrapper = React.useCallback(
        ({ children, ...props }: any) => {
            if (loading || !props?.to) return <div {...props}>{children}</div>;

            return <Link {...props}>{children}</Link>;
        },
        [loading]
    );

    return (
        <Wrapper to={link} className="card">
            <Skeleton loading={loading} height={200}>
                <div className="content">
                    <p className="title">
                        <Skeleton loading={loading} width={150} height={20}>
                            {title}
                        </Skeleton>
                    </p>
                    <div className="image-wrap">
                        <Skeleton loading={loading} width={180} height={20}>
                            <img src={image} alt="Card Item" draggable="false" className="image" />
                        </Skeleton>
                    </div>
                </div>
            </Skeleton>
        </Wrapper>
    );
}
