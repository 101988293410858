import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Button from '../custom/button';
import Skeleton from '../custom/skeleton';

interface Props {
    hideOnMobile?: boolean;
    loading?: boolean;
    image: string;
    alt?: string;
    title: string;
    link?: LinkProps;
    price: string | number | null;
    button?: {
        name?: string;
    };
}

export default function DetailCard({
    hideOnMobile = false,
    loading = false,
    image,
    alt,
    title,
    price,
    link = { to: '/' },
    button = { name: 'Request This Product' }
}: Props) {
    const Wrapper = ({ children, ...props }: any) => {
        if (loading) return <div {...props}>{children}</div>;

        return <Link {...props}>{children}</Link>;
    };

    return (
        <Wrapper {...link} className="detail-card" data-hide-on-mobile={hideOnMobile}>
            <div className="content">
                <div className="image-wrap">
                    <Skeleton loading={loading} height={270}>
                        <img src={image} alt={alt || 'Cover'} className="image" />
                    </Skeleton>
                </div>

                <div className="details">
                    <Skeleton loading={loading} height={25}>
                        <p className="title">{title}</p>
                    </Skeleton>
                    <Skeleton loading={loading} width={80} height={25}>
                        <p className="price">
                            <span className="currency">KWD</span> {price === Infinity ? 0 : price}
                        </p>
                    </Skeleton>
                    <Skeleton loading={loading} height={40}>
                        <Button size="medium" variant="contained" className="hover-btn no-shadow">
                            {button?.name}
                        </Button>
                    </Skeleton>
                </div>
            </div>
        </Wrapper>
    );
}
