import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '../../components/custom/button';
import IconButton from '../../components/custom/icon-button';
import TextField from '../../components/custom/text-field';
import Icon from '../../components/icon';
import { useDispatch } from 'react-redux';
import { navbarActions } from '../../redux/reducers/navbar';
import { AccountButton } from '../../components/account-btn';

export function Desktop() {
    return (
        <div className="navbar dashboard">
            <div className="top"></div>
            <div className="body">
                <Link to="/" className="logo-wrap">
                    <img src="/images/logo.jpg" alt="Logo" className="logo" />
                </Link>

                <div className="buttons">
                    {/*  <IconButton className="notification-btn">
                        <Icon variant="outlined" className="icon w-500">
                            notifications
                        </Icon>
                    </IconButton> */}
                    <AccountButton />
                </div>
            </div>
        </div>
    );
}

export function Mobile() {
    const dispatch = useDispatch();
    const location = useLocation();

    const [menu, setMenu] = React.useState(false);
    const [dashboardMenu, setDashboardMenu] = React.useState(false);

    React.useEffect(() => {
        if (menu) {
            disable();
        } else {
            enable();
        }
    }, [menu]);

    const onMenu = () => {
        if (location.pathname.includes('dashboard')) {
            if (dashboardMenu) {
                dispatch(navbarActions.close());
            } else {
                dispatch(navbarActions.open());
            }

            setDashboardMenu(!dashboardMenu);
            setMenu(false);
            return;
        }

        setMenu(!menu);
    };

    const disable = () => {
        document.body.classList.add('disable-scroll');
    };

    const enable = () => {
        document.body.classList.remove('disable-scroll');
    };

    return (
        <div className="navbar mobile dashboard">
            <div className="top"></div>

            <div className="body">
                <div className="wrap">
                    <div className="logo-wrap">
                        <IconButton className="menu-button" onClick={onMenu}>
                            <Icon className="icon">menu</Icon>
                        </IconButton>

                        <Link to="/" className="logo-wrap" onClick={() => setMenu(false)}>
                            <img src="/images/logo.jpg" alt="Logo" className="logo" />
                        </Link>
                    </div>

                    <div className="buttons">
                        {/* <IconButton className="notification-btn" onClick={() => setMenu(false)}>
                            <Icon variant="outlined" className="icon w-500">
                                notifications
                            </Icon>
                        </IconButton> */}

                        <AccountButton mobile={true} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function PageNavbar() {
    const [mobile, setMobile] = React.useState(false);

    React.useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const onResize = () => setMobile(window.innerWidth < 1150);

    return mobile ? <Mobile /> : <Desktop />;
}
