import { createSlice } from '@reduxjs/toolkit';

const initState: any = {
    open: false
};

const navbarSlice = createSlice({
    name: 'navbar',
    initialState: initState,
    reducers: {
        open(state) {
            state.open = true;
        },
        close(state) {
            state.open = false;
        }
    }
});

export const navbarActions = navbarSlice.actions;

export default navbarSlice.reducer;
