const { REACT_APP_NODE_ENV, REACT_APP_API_URL } = process.env;

const config = {
    node_env: REACT_APP_NODE_ENV as string,
    server_urls: {
        api: formatUrl(REACT_APP_API_URL)
    },
    details: {
        address: {
            label: 'Floor 6, Hamra Mall Complex, Habeeb Al-Munawer St. 350, Block 4 NA, Farwaniya, 85000 Kuwait',
            value: 'https://www.google.com/maps/search/Floor+6,+Hamra+Mall+Complex,+Habeeb+Al-Munawer+St.+350,+Block+4+NA,+Farwaniya,+85000+Kuwait/@29.2777562,47.9598331,17z/data=!3m1!4b1?entry=ttu'
        },
        email: {
            label: 'info@smartco.live',
            value: 'info@smartco.live'
        },
        phone: {
            label: '69 966 882',
            value: '69966882'
        }
    }
};

function formatUrl(url: any) {
    if (!url) return '';

    return url.slice(-1) === '/' ? url : url + '/';
}

export default config;
