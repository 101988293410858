import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { createProps, updateProps } from '../interfaces/payment/handover.interface';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps
} from '../interfaces/common.interface';

const handoverPaymentServices = createApi({
    reducerPath: 'handover-payment-service',
    baseQuery: baseQuery,
    tagTypes: ['HandoverPayment', 'HandoverPayments', 'HandoverPaymentOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/handover-payments/create',
                body: data
            }),
            invalidatesTags: [{ type: 'HandoverPayments' }, { type: 'HandoverPaymentOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/handover-payments/update',
                body: data
            }),
            invalidatesTags: [{ type: 'HandoverPayments' }, { type: 'HandoverPaymentOptions' }]
        }),
        get: builder.query({
            query: ({ type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/handover-payments/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'HandoverPayment' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/handover-payments/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'HandoverPayments' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/handover-payments/options`,
                body: props
            }),
            providesTags: [{ type: 'HandoverPaymentOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/handover-payments/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'HandoverPayments' }, { type: 'HandoverPaymentOptions' }]
        })
    })
});

export default handoverPaymentServices;
