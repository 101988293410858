import * as React from 'react';
import MuiBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

export default React.memo(function BackdropContainer() {
    const { open, showValue, value, text } = useSelector((state: any) => state.backdrop);

    return (
        <MuiBackdrop
            className="back-drop"
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}>
            <div className="progress-wrap">
                <CircularProgress size="4rem" color="inherit" value={value} />
                <div className="value-wrap">
                    <span className="value">{showValue && `${Math.round(value)}%`}</span>
                </div>
            </div>
            {text && <p className="text">{text}</p>}
        </MuiBackdrop>
    );
});
