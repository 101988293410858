import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';

interface Props extends IconButtonProps {
    role?: string;
    restrict?: string[];
    component?: string;
    tooltip?: {
        title?: string;
        placement?:
            | 'bottom'
            | 'left'
            | 'right'
            | 'top'
            | 'bottom-end'
            | 'bottom-start'
            | 'left-end'
            | 'left-start'
            | 'right-end'
            | 'right-start'
            | 'top-end'
            | 'top-start'
            | undefined;
    };
}

export default function IconButton({
    role = '',
    restrict = [],
    className,
    tooltip = { title: '', placement: 'bottom' },
    children,
    disabled = false,
    ...props
}: Props) {
    if (restrict.includes(role)) return null;

    return !disabled ? (
        <Tooltip title={tooltip?.title} placement={tooltip?.placement} {...tooltip}>
            <MuiIconButton
                className={`icon-button hover-theme ${className ? className : ''}`}
                {...props}>
                {children}
            </MuiIconButton>
        </Tooltip>
    ) : (
        <MuiIconButton
            disabled={disabled}
            className={`icon-button hover-theme ${className ? className : ''}`}
            {...props}>
            {children}
        </MuiIconButton>
    );
}
