import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps,
    searchProps
} from '../interfaces/common.interface';

const priceFeatureServices = createApi({
    reducerPath: 'price-feature-service',
    baseQuery: baseQuery,
    tagTypes: ['PriceFeature', 'PriceFeatures', 'PriceFeatureOptions'],
    endpoints: (builder) => ({
        get: builder.query({
            query: ({ type, value }: getProps) => ({
                method: 'POST',
                url: `/price-features/get/${type}/${value}`
            }),
            providesTags: [{ type: 'PriceFeature' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/price-features/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'PriceFeatures' }]
        }),
        search: builder.query({
            query: (props: searchProps) => ({
                method: 'GET',
                url: `/price-features/search`,
                params: props
            }),
            providesTags: [{ type: 'PriceFeatures' }, { type: 'PriceFeatureOptions' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/price-features/options`,
                body: props
            }),
            providesTags: [{ type: 'PriceFeatureOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/price-features/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'PriceFeatures' }, { type: 'PriceFeatureOptions' }]
        })
    })
});

export default priceFeatureServices;
