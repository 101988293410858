import React from 'react';
import MuiCircularProgress from '@mui/material/CircularProgress';

export default function CircularProgress() {
    return (
        <div className="circular-progress">
            <MuiCircularProgress className="progress" />
        </div>
    );
}
