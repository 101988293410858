import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { createProps, updateProps } from '../interfaces/product/product.interface';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps,
    searchProps
} from '../interfaces/common.interface';

const productServices = createApi({
    reducerPath: 'product-service',
    baseQuery: baseQuery,
    tagTypes: ['Product', 'Products', 'ProductOptions', 'AllProducts', 'AllProductOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/products/create',
                body: data
            }),
            invalidatesTags: [{ type: 'Products' }, { type: 'ProductOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/products/update',
                body: data
            }),
            invalidatesTags: [{ type: 'Products' }, { type: 'ProductOptions' }]
        }),
        get: builder.query({
            query: ({ id }) => ({
                method: 'GET',
                url: `/products/get/${id}`
            }),
            providesTags: [{ type: 'Product' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/products/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Products' }]
        }),
        searchAll: builder.query({
            query: (props: searchProps) => ({
                method: 'GET',
                url: `/products/search/all`,
                params: props
            }),
            providesTags: [{ type: 'AllProducts' }, { type: 'AllProductOptions' }]
        }),
        search: builder.query({
            query: (props: searchProps) => ({
                method: 'GET',
                url: `/products/search`,
                params: props
            }),
            providesTags: [{ type: 'Products' }, { type: 'ProductOptions' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/products/options`,
                body: props
            }),
            providesTags: [{ type: 'ProductOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/products/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Products' }, { type: 'ProductOptions' }]
        })
    })
});

export default productServices;
