import { createSlice } from '@reduxjs/toolkit';

interface actionPayload {
    payload: {
        showValue?: boolean;
        value?: number;
        text?: string;
    };
}

const initState: any = {
    open: false,
    showValue: false,
    value: 0,
    text: ''
};

const backdropSlice = createSlice({
    name: 'backdrop',
    initialState: initState,
    reducers: {
        open(state, { payload }: actionPayload) {
            state.open = true;

            if (payload.showValue !== null || payload.showValue !== undefined)
                state.showValue = payload.showValue;
            if (payload.value !== null || payload.value !== undefined) state.value = payload.value;
            if (payload.text) state.text = payload.text;
        },
        close(state) {
            state.open = false;
            state.showValue = false;
            state.value = 0;
            state.text = '';
        },
        update(state, { payload }: actionPayload) {
            if (payload.showValue !== null || payload.showValue !== undefined)
                state.showValue = payload.showValue;
            if (payload.value !== null || payload.value !== undefined) state.value = payload.value;
            if (payload.text) state.text = payload.text;
        }
    }
});

export const backdropActions = backdropSlice.actions;

export default backdropSlice.reducer;
