import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { updateProps, updateLinkProps } from './interfaces/banner.interface';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';

const bannerServices = createApi({
    reducerPath: 'banners-service',
    baseQuery: baseQuery,
    tagTypes: ['Banner', 'Banners', 'BannerOptions'],
    endpoints: (builder) => ({
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PUT',
                url: '/banners/update',
                body: data
            }),
            invalidatesTags: [{ type: 'Banners' }, { type: 'BannerOptions' }]
        }),
        updateLink: builder.mutation({
            query: (data: updateLinkProps) => ({
                method: 'PUT',
                url: '/banners/update/link',
                body: data
            }),
            invalidatesTags: [{ type: 'Banners' }, { type: 'BannerOptions' }]
        }),
        get: builder.query({
            query: ({ type, value }: getProps) => ({
                method: 'POST',
                url: `/banners/get/${type}/${value}`
            }),
            providesTags: [{ type: 'Banner' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/banners/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Banners' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/banners/options`,
                body: props
            }),
            providesTags: [{ type: 'BannerOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/banners/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'Banners' }, { type: 'BannerOptions' }]
        })
    })
});

export default bannerServices;
