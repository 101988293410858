import React from 'react';
import IconButton from '../custom/icon-button';
import Icon from '../icon';

interface Props {
    type: 'right' | 'left';
    onClick?: any;
}

export default function NavArrow({ type = 'right', onClick }: Props) {
    return (
        <div
            className={`nav-arrow react-multiple-carousel__arrow react-multiple-carousel__arrow--${type}`}>
            <IconButton onClick={onClick}>
                <Icon className="Icon w-600">
                    {type === 'right' ? 'navigate_next' : 'navigate_before'}
                </Icon>
            </IconButton>
        </div>
    );
}
