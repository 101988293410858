import React from 'react';
import Button from './custom/button';
import { Avatar, ClickAwayListener, Popper } from '@mui/material';
import Icon from './icon';
import { notifyActions } from '../redux/reducers/notify';
import { useDispatch, useSelector } from 'react-redux';
import userServices from '../services/user.services';
import { userActions } from '../redux/reducers/user';
import { logoutActions } from '../redux/reducers/logout';
import { Link, useNavigate } from 'react-router-dom';

export const AccountButton = ({ mobile }: any) => {
    const navigator = useNavigate();
    const dispatch = useDispatch();
    const { user: authUser }: any = useSelector((state: any) => state.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openProfileMenu, setOpenProfileMenu] = React.useState(false);

    const {
        data: user,
        error: userProfileError,
        isError: isUserProfileError,
        isLoading: isUserProfileLoading
    }: any = userServices.useGetQuery(
        {
            type: 'id',
            value: authUser?.id
        },
        { skip: !authUser?.id }
    );

    /* error handling */
    React.useEffect(() => {
        if (isUserProfileError) {
            dispatch(
                notifyActions.open({
                    type: 'error',
                    message: userProfileError.data?.message
                })
            );
        }
    }, [userProfileError, isUserProfileError]);

    /* functions */
    const formatName = (string: any) => {
        if (!string) return '-';

        return string?.length > 10 ? `${string.slice(0, 10)}..` : string;
    };

    const onProfileMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenProfileMenu(!openProfileMenu);
    };

    const onLogout = () => {
        setOpenProfileMenu(!openProfileMenu);
        dispatch(logoutActions.open());

        setTimeout(() => {
            dispatch(userActions.remove());
        }, 200);
    };

    return (
        <div className="account-wrap">
            {authUser?.id ? (
                <>
                    <Popper
                        className="popper w-mx mt-10"
                        open={openProfileMenu}
                        anchorEl={anchorEl}
                        placement="bottom-end">
                        <div className="item" onClick={() => navigator('/dashboard')}>
                            <Icon variant="outlined" className="icon">
                                dashboard
                            </Icon>
                            <span className="title">Dashboard</span>
                        </div>
                        <div className="item" onClick={onLogout}>
                            <Icon className="icon">logout</Icon>
                            <span className="title">Logout</span>
                        </div>
                    </Popper>

                    <Button onClick={onProfileMenu} variant="text" className="account-btn">
                        {user?.data?.image ? (
                            <Avatar
                                alt={user?.data?.first_name}
                                src={user?.data?.image}
                                className="icon"
                            />
                        ) : (
                            <Icon className="icon">account_circle</Icon>
                        )}
                        {!mobile && (
                            <span className="name">{formatName(user?.data?.first_name)}</span>
                        )}
                    </Button>
                </>
            ) : (
                <Link to="/login">
                    <Button variant="text" className="account-btn">
                        <Icon variant="outlined" className="icon">
                            account_circle
                        </Icon>
                        {!mobile && 'Login'}
                    </Button>
                </Link>
            )}
        </div>
    );
};
