import * as React from 'react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

export interface ButtonProps extends LoadingButtonProps {
    role?: string;
    restrict?: string[];
    component?: string;
}

export default function Button({
    role = '',
    restrict = [],
    variant = 'outlined',
    size = 'small',
    className,
    children,
    ...props
}: ButtonProps) {
    if (restrict.includes(role)) return null;

    return (
        <LoadingButton
            className={`button ${className ? className : ''}`}
            variant={variant}
            size={size}
            {...props}>
            {children}
        </LoadingButton>
    );
}
