import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { deleteProps, getAllProps, getOptionsProps, getProps } from './interfaces/common.interface';
import {
    createProps,
    updateUserProps,
    passwordUserProps,
    forgotPasswordUserProps,
    resetPasswordUserProps,
    userStateProps
} from './interfaces/users.interface';

const userServices = createApi({
    reducerPath: 'users-service',
    baseQuery: baseQuery,
    tagTypes: ['User', 'Users', 'UsersOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: ({ user_id, ...props }: createProps) => ({
                method: 'POST',
                url: `/users/${user_id}/create`,
                body: props
            }),
            invalidatesTags: [{ type: 'Users' }, { type: 'UsersOptions' }]
        }),
        get: builder.query({
            query: ({ type, value }: getProps) => ({
                method: 'POST',
                url: `/users/get/${type}/${value}`
            }),
            providesTags: [{ type: 'User' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/users/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'Users' }, { type: 'UsersOptions' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/users/options`,
                body: props
            }),
            providesTags: [{ type: 'UsersOptions' }]
        }),
        update: builder.mutation({
            query: ({ ...props }: updateUserProps) => ({
                method: 'PATCH',
                url: `/users/update`,
                body: props
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        }),
        state: builder.mutation({
            query: ({ user_id, ...data }: userStateProps) => ({
                method: 'PUT',
                url: `/users/${user_id}/state`,
                body: data
            }),
            invalidatesTags: [{ type: 'Users' }, { type: 'UsersOptions' }]
        }),
        updatePassword: builder.mutation({
            query: ({ ...props }: passwordUserProps) => ({
                method: 'PATCH',
                url: `/users/change-password`,
                body: props
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        }),
        resetPassword: builder.mutation({
            query: ({ ...props }: resetPasswordUserProps) => ({
                method: 'POST',
                url: `/users/reset-password`,
                body: props
            })
        }),
        delete: builder.mutation({
            query: ({ user_id, ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/users/delete/${user_id}`,
                body: { user_id, ...props }
            }),
            invalidatesTags: [{ type: 'User' }, { type: 'Users' }, { type: 'UsersOptions' }]
        })
    })
});

export default userServices;
