import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { createProps, updateProps } from '../interfaces/payment/down.interface';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps
} from '../interfaces/common.interface';

const downPaymentServices = createApi({
    reducerPath: 'down-payment-service',
    baseQuery: baseQuery,
    tagTypes: ['DownPayment', 'DownPayments', 'DownPaymentOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/down-payments/create',
                body: data
            }),
            invalidatesTags: [{ type: 'DownPayments' }, { type: 'DownPaymentOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/down-payments/update',
                body: data
            }),
            invalidatesTags: [{ type: 'DownPayments' }, { type: 'DownPaymentOptions' }]
        }),
        get: builder.query({
            query: ({ type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/down-payments/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'DownPayment' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/down-payments/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'DownPayments' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/down-payments/options`,
                body: props
            }),
            providesTags: [{ type: 'DownPaymentOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/down-payments/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'DownPayments' }, { type: 'DownPaymentOptions' }]
        })
    })
});

export default downPaymentServices;
