import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base-query';
import { createProps, updateProps } from '../interfaces/payment/order.interface';
import {
    deleteProps,
    getAllProps,
    getOptionsProps,
    getProps
} from '../interfaces/common.interface';

const orderPaymentServices = createApi({
    reducerPath: 'order-payment-service',
    baseQuery: baseQuery,
    tagTypes: ['OrderPayment', 'OrderPayments', 'OrderPaymentOptions'],
    endpoints: (builder) => ({
        create: builder.mutation({
            query: (data: createProps) => ({
                method: 'POST',
                url: '/order-payments/create',
                body: data
            }),
            invalidatesTags: [{ type: 'OrderPayments' }, { type: 'OrderPaymentOptions' }]
        }),
        update: builder.mutation({
            query: (data: updateProps) => ({
                method: 'PATCH',
                url: '/order-payments/update',
                body: data
            }),
            invalidatesTags: [{ type: 'OrderPayments' }, { type: 'OrderPaymentOptions' }]
        }),
        get: builder.query({
            query: ({ type, value, ...props }: getProps) => ({
                method: 'POST',
                url: `/order-payments/get/${type}/${value}`,
                body: props
            }),
            providesTags: [{ type: 'OrderPayment' }]
        }),
        getAll: builder.query({
            query: ({ page, ...props }: getAllProps) => ({
                method: 'POST',
                url: `/order-payments/all/${page}`,
                body: props
            }),
            providesTags: [{ type: 'OrderPayments' }]
        }),
        options: builder.query({
            query: ({ ...props }: getOptionsProps) => ({
                method: 'POST',
                url: `/order-payments/options`,
                body: props
            }),
            providesTags: [{ type: 'OrderPaymentOptions' }]
        }),
        delete: builder.mutation({
            query: ({ ...props }: deleteProps) => ({
                method: 'DELETE',
                url: `/order-payments/delete`,
                body: props
            }),
            invalidatesTags: [{ type: 'OrderPayments' }, { type: 'OrderPaymentOptions' }]
        })
    })
});

export default orderPaymentServices;
