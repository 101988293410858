import { createSlice } from '@reduxjs/toolkit';

interface actionPayload {
    payload: { id: string };
}

const initState: any = {
    product: {}
};

const productSlice = createSlice({
    name: 'product',
    initialState: initState,
    reducers: {
        set(state, { payload }: actionPayload) {
            state.product = payload;
        },
        reset(state) {
            state.product = {};
        }
    }
});

export const productActions = productSlice.actions;

export default productSlice.reducer;
