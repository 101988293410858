import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { createProps } from './interfaces/s3.interface';

const s3Services = createApi({
    reducerPath: 's3-service',
    baseQuery: baseQuery,
    tagTypes: ['S3'],
    endpoints: (builder) => ({
        upload: builder.mutation({
            query: (data: createProps) => ({
                method: 'PUT',
                url: '/s3/upload',
                body: data
            })
        })
    })
});

export default s3Services;
