import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './base-query';
import { sendProps } from './interfaces/sms.interface';

const smsServices = createApi({
    reducerPath: 'sms-service',
    baseQuery: baseQuery,
    tagTypes: ['SMS'],
    endpoints: (builder) => ({
        send: builder.mutation({
            query: (data: sendProps) => ({
                method: 'POST',
                url: '/sms/send',
                body: data
            })
        })
    })
});

export default smsServices;
